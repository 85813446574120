import { MicrositeContext } from 'context';
import { useContext } from 'react';
import { useFeature } from 'services/growthbook/useFeature';

export function useShowProviderLandingPage() {
  const {
    enableAppfolioLanding = false,
    enableMicrositeLanding = false,
    name,
  } = useContext(MicrositeContext);

  const { on: appfolioLandingFlagEnabled, loading: loadingAppfolioFlag } =
    useFeature('provider_app_appfolio_landing');

  /**
   * Jira - https://updater.atlassian.net/browse/TVI-1984
   * Growthbook - https://growthbook.prod.updatron.tools/features/provider_app_rate_landing
   */
  const { on: RateLandingFlagEnabled, loading: loadingRateFlag } = useFeature(
    'provider_app_rate_landing'
  );

  const featureFlagMap = {
    AppFolio: appfolioLandingFlagEnabled,
    RatePartner: RateLandingFlagEnabled,
  };

  const landingPageEnabled = enableAppfolioLanding || enableMicrositeLanding;
  const featureFlagEnabled = featureFlagMap?.[name] ?? false;

  const showLandingPage = landingPageEnabled && featureFlagEnabled;
  const loading = loadingRateFlag || loadingAppfolioFlag;

  return { showLandingPage, loading };
}
