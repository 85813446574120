import { SpinnerFullPage } from '@updater/ui-uds';
import { useShowProviderLandingPage } from 'hooks/useShowProviderLandingPage';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

/**
 * Why does this component handle quiz & landing page?
 * The app was originally built around the catch-all route, [[...quiz]].tsx,
 * as the first page the user lands on.
 *
 * For now we don't know what would break bc /services used to be the first page.
 * So we direct all users to /quiz/services or /landing here now.
 */
export const Redirector = () => {
  const router = useRouter();

  const { showLandingPage, loading: loadingShowLandingPage } =
    useShowProviderLandingPage();

  useEffect(() => {
    if (loadingShowLandingPage) return;
    if (showLandingPage) {
      router.replace('/landing');
      return;
    } else {
      router.replace('/quiz');
      return;
    }
  }, [loadingShowLandingPage, router, showLandingPage]);

  return <SpinnerFullPage />;
};
