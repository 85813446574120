import { ProviderWrapper } from 'components';
import { Redirector } from 'components/Redirector';

/**
 * Why does this page handle quiz & landing page?
 * The app was originally built around the catch-all route, [[...quiz]].tsx,
 * as the first page the user lands on.
 *
 * For now we don't know what would break bc /services used to be the first page.
 * So we direct all users to /quiz/services or /landing here now.
 */
export default function IndexPage() {
  return (
    <ProviderWrapper>
      <Redirector />
    </ProviderWrapper>
  );
}
